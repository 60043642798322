import React, { useEffect, useState, useRef } from "react";

import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
   <> <Router>
   <Navbar />
   <HeroSection/>
   <Routes>
   
   </Routes>
 </Router>
  
   
 </> 
  );
}

export default App;
